import React from "react"
import "antd/dist/antd.less"

import Index from "./components/Page-Index"

const App = () => {
  return (
    <div className="default-theme">
      <Index />
    </div>
  )
}

export default App
