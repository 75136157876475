import React, { useEffect } from "react"
import { Divider, Dropdown, Menu } from "antd"
import { ArrowRightOutlined, HomeOutlined, MenuOutlined, MessageOutlined, RocketOutlined } from "@ant-design/icons"
import ScrollAnimation from "react-animate-on-scroll"
import Typewriter from "typewriter-effect"

import imgInimicodeLogo from "../media/logo-inimicode-light-155x31.png"
import imgInimicodeLogoLarge from "../media/logo-inimicode-light-cropped-312x70.png"
import imgCustomisedEnterpriseSolution from "../media/Image-LoFiDesign.jpg"
import imgWebDevelopment from "../media/Image-Laptop.jpg"
import imgDigitalSystemSupportAndMaintenance from "../media/Image-Discussion.jpg"
import imgISAP from "../media/Image-Security.jpg"
import { Helmet } from "react-helmet-async"

const Index = () => {
    const nav = [
        { title: "Home", link: "#inimicode", icon: <HomeOutlined /> },
        { title: "Services", link: "#services", icon: <RocketOutlined /> },
        // { title: "Product", link: "#product" },
        { title: "Contact", link: "#contact", icon: <MessageOutlined /> },
    ]

    const renderNavItems = (array) => {
        const navItems = array.map((item) => {
            return (
                <a href={item.link} className={item.title === "Home" ? "nav-item active" : "nav-item"} key={item.title}>
                    {item.icon}
                </a>
            )
        })
        return navItems
    }

    const renderNav = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderNavItems(array)
            )
        )
    }

    const renderDropdownNavItems = (array) => {
        const menuItems = array.map((item) => {
            return (
                <Menu.Item key={item.title} >
                    <a href={item.link}>{item.title}</a>
                </Menu.Item>
            )
        })
        return menuItems
    }

    const renderDropdownMenuItems = (array) => {
        return (
            (array.length === 0) ? (
                ""
            ) : (
                renderDropdownNavItems(array)
            )
        )
    }

    const dropdownMenuContent = (array) => {
        return (
            <Menu style={{ maxWidth: "250px" }}>
                {renderDropdownMenuItems(array)}
            </Menu>
        )
    }

    const handleScrollActive = (sections) => {
        const scrollY = window.pageYOffset
        console.log("=== scrollY", scrollY);
        console.log("inimicode offset Top", sections[1].offsetTop);
        console.log("service offset Top", sections[2].offsetTop);
        console.log("contact offset Top", sections[3].offsetTop);
        console.log("contact offset Height", sections[3].offsetHeight);
        sections.forEach(current => {
            const sectionHeight = current.offsetHeight,
                sectionTop = current.offsetTop - 150,
                sectionId = current.getAttribute("id")

            if (sectionId !== "root") {
                if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                    document.querySelector('.nav a[href*=' + sectionId + ']').classList.add('active')
                }
                else {
                    document.querySelector('.nav a[href*=' + sectionId + ']').classList.remove('active')
                }
            }
        })
    }

    useEffect(() => {
        const sections = document.querySelectorAll("div[id]")
        console.log("sections", sections)
        window.addEventListener("scroll", () => handleScrollActive(sections))

        return () => {
            window.removeEventListener("scroll", () => handleScrollActive(sections))
        }
    }, [])

    return (
        <div className="index">
            <Helmet prioritizeSeoTags>
                <link rel="canonical" href="/" />
                <meta name="description" content="Making business process simple and efficient with Information Technology" />
            </Helmet>
            <div className="container">
                {/* <div className="header">
                    <a href="#inimicode">
                        <div className="logo">
                            <img src={imgInimicodeLogo} />
                        </div>
                    </a>
                    <div className="row nav above-sm">
                        {renderNav(nav)}
                    </div>
                    <Dropdown overlay={dropdownMenuContent(nav)} trigger={["click"]}>
                        <MenuOutlined className="below-sm" />
                    </Dropdown>
                </div> */}
                <div className="row nav">
                    {renderNav(nav)}
                </div>
                <div className="inimicode" id="inimicode">
                    <div className="column">
                        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                            <div className="row">
                                <div className="logo">
                                    <img src={imgInimicodeLogoLarge} width={280} height={63} loading="eager" alt={"Logo"} title={"Logo"} />
                                </div>
                                <span className="logo-underscore">__</span>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={500}>
                            <div className="tagline">
                                Be Inimitable
                                <h1 style={{display:"none"}}>Inimicode, Be Inimitable, Make business simple and efficient with Tech</h1>
                            </div>
                        </ScrollAnimation>
                        <Divider />
                        <div className="why">
                            {/* Making business process simple with Information Technology */}
                            <span className="text-normal">Making business process&nbsp;</span>
                            <Typewriter
                                options={{
                                    autoStart: true,
                                    loop: true,
                                }}
                                onInit={(typewriter) => {
                                    typewriter.typeString(`<span class="text-type">SIMPLE</span>`)
                                        .pauseFor(2000)
                                        .deleteAll()
                                        .typeString("<span class='text-type'>EFFICIENT</span")
                                        .pauseFor(2000)
                                        .start();
                                }}
                            />
                            <span className="text-normal">with Information Technology</span>
                        </div>
                    </div>
                </div>
                <div className="services" id="services">
                    <div className="title">
                        Services
                    </div>
                    <div className="row">
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                <div className="image">
                                    <img src={imgCustomisedEnterpriseSolution} width={423} height={281} loading="lazy" alt={"Customised Enterprise Solution"} title={"Customised Enterprise Solution"} />
                                </div>
                                <div className="item-title">
                                    Customised Enterprise Solution
                                </div>
                                <div className="item-subtitle">
                                    Design and develop IT solutions to fit your business processes
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={200}>
                                <div className="image">
                                    <img src={imgWebDevelopment} width={423} height={281} loading="lazy" alt={"Web Development"} title={"Web Development"} />
                                </div>
                                <div className="item-title">
                                    Web Development
                                </div>
                                <div className="item-subtitle">
                                    <ul>
                                        <li>Company website</li>
                                        <li>Wesbsite revamp</li>
                                        <li>Web application</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="item">
                            <ScrollAnimation animateIn="slideInUp" animateOnce={true} delay={400}>
                                <div className="image">
                                    <img src={imgDigitalSystemSupportAndMaintenance} width={423} height={281} loading="lazy" alt={"Digital System Support and Maintenance"} title={"Digital System Support and Maintenance"} />
                                </div>
                                <div className="item-title">
                                    Digital System Support &amp; Maintenance
                                </div>
                                <div className="item-subtitle">
                                    <ul>
                                        <li>Web hosting</li>
                                        <li>Domain registration and management</li>
                                        <li>IT consultation</li>
                                    </ul>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                {/* <div className="products" id="product">
                    <div className="title">
                        Product
                    </div>
                    <div className="row">
                        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                            <div className="item">
                                <a href="https://www.isap.inimicode.com" target="_blank" className="nolinkstyle">
                                    <div className="image">
                                        <img src={imgISAP} />
                                    </div>
                                    <div className="item-title">
                                        Inimicode Security Agency Portal
                                    </div>
                                    <div className="container-overlay">
                                        <div className="item-subtitle">
                                            Platform designed for security agencies to support their daily operations
                                        </div>
                                        <div className="item-button-hover">
                                            Learn More <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div> */}
                <div className="contact" id="contact">
                    <div className="title">
                        Contact
                    </div>
                    <div className="row">
                        <div className="item">
                            <div className="item-title">
                                Email
                            </div>
                            <div className="item-subtitle">
                                <span>
                                    We'll respond within 3 to 5 working days of your request.
                                </span>
                                <br /><br />
                                <span className="highlight">
                                    info@inimicode.com
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    Copyright &copy; 2022 Inimicode. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Index